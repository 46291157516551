
import { Vue, Component } from 'vue-property-decorator';
import Header from '@/components/Header/Header.vue';
import SkeletonSection from '@/components/Skeleton/SkeletonSection.vue';
import SkeletonCard from '@/components/Skeleton/SkeletonCard.vue';
import ButtonDefault from '@/components/shared/ButtonDefault.vue';
import { RouteName, SidebarTypes } from '@/models';
import { SidebarService } from '@/services';

const { PENDING_ASSIGNMENTS } = RouteName;

@Component({
  name: 'RestrictedAccess',
  components: {
    Header,
    SkeletonSection,
    SkeletonCard,
    ButtonDefault,
  },
})
export default class RestrictedAccess extends Vue {
  public goToPendingAssignments() {
    this.$router.push({ name: PENDING_ASSIGNMENTS });
  }

  public changeCourse() {
    // TODO: trocar o tipo para quanto o card para curso estiver feito
    SidebarService.abrirSidebar({
      component: SidebarTypes.COURSESIDEBAR,
      fechavel: true,
    });
  }
}
